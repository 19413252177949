import vAccordion from './v-accordion.vue'
import vAccordionHeader from './v-accordion-header.vue'
import vAccordionContent from './v-accordion-content.vue'
import vAvatar from './v-avatar.vue'
import vButton from './v-button.vue'
import vButtonAnimated from './v-button-animated.vue'
import vButtonSave from './v-button-save.vue'
import vCarousel from './v-carousel.vue'
import vCheckbox from './v-checkbox.vue'
import vChips from './v-chips.vue'
import vDatalist from './v-datalist.vue'
import vDownload from './v-download.vue'
import vDrawer from './v-drawer-vertical.vue'
import vDropdown from './v-dropdown.vue'
import vImage from './v-image.vue'
import vInputColor from './v-input-color.vue'
import vInputIcon from './v-input-icon.vue'
import vInputImage from './v-input-image.vue'
import vInputFile from './v-input-file.vue'
import vInputRange from './v-input-range.vue'
import vInputSearch from './v-input-search.vue'
import vInputText from './v-input-text.vue'
import vInputTextEditor from './v-input-text-editor.vue'
import vLink from './v-link.vue'
import vPagination from './v-pagination.vue'
import vSurveyItem from './v-survey-item.vue'
import vTabs from './v-tabs.vue'
import vTabsContent from './v-tabs-content.vue'
import vTextarea from './v-textarea.vue'
import vToolbar from './v-toolbar.vue'
import vValidated from './v-validated.vue'
import vViewer from './v-viewer.vue'

export default {
  vAccordion,
  vAccordionHeader,
  vAccordionContent,
  vAvatar,
  vButton,
  vButtonAnimated,
  vButtonSave,
  vCarousel,
  vCheckbox,
  vChips,
  vDatalist,
  vDownload,
  vDrawer,
  vDropdown,
  vImage,
  vInputColor,
  vInputIcon,
  vInputImage,
  vInputFile,
  vInputRange,
  vInputSearch,
  vInputText,
  vInputTextEditor,
  vLink,
  vPagination,
  vSurveyItem,
  vTabs,
  vTabsContent,
  vTextarea,
  vToolbar,
  vValidated,
  vViewer,
}