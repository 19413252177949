<template>
<v-modal width="26em"
  :modelValue="modelValue"
  @close="$emit('update:modelValue', false)">

  <template #content>
    <div class="modal-confirm__title"
      v-if="title">
      {{ title }}
    </div>
    <div class="modal-confirm__text"
      v-if="message"
      v-html="message">
    </div>
    <div class="modal-confirm__buttons">
      <button class="button-tertiary modal-confirm__button"
        v-if="labelDiscard"
        @click="discard">
        {{ labelDiscard }}
      </button>
      <button class="button-primary modal-confirm__button"
        v-if="labelConfirm"
        @click="confirm">
        {{ labelConfirm }}
      </button>
    </div>
  </template>

</v-modal>
</template>

<script>
import vModal from './v-modal.vue'
export default {
  components: { vModal },
  props: {
    modelValue: { type: Boolean, required: false, default: false },
    title: { type: String, required: false, default: undefined },
    message: { type: String, required: true },
    labelConfirm: { type: String, required: false, default: "Так" },
    labelDiscard: { type: String, required: false, default: "Ні" },
  },
  data() {
    return {
      promise: null,
      resolve: null,
      reject: null,
    }
  },
  created() { 
    this.promise = new Promise((res,rej) => {
      this.resolve = res
      this.reject = rej
    })
  },
  methods: {
    confirm() { 
      this.resolve(true) 
    },
    discard() { 
      this.resolve(false)
    }
  }
}
</script>

<style lang="scss">
.modal-confirm {
  &__title {
    font-size: 1.5em;
    font-weight: 700;
    color: $cmain;
  }
  &__text {
    text-align: left !important;
    line-height: 1.5;
  }
  &__buttons {
    display: flex;
    gap: 1.5em;
  }
  &__button {
    flex-basis: 50%;
  }
}
</style>