<template>
<div class="v-input-search">
  <input class="v-input-search__input"
    ref="input"
    type="text"
    :value="modelValue"
    v-bind="{ disabled, placeholder }"
    @input="input($event)"
    @keyup.enter="submit()">
  <i class="material-symbols v-input-search__icon"
    @click="submit()">
    search
  </i>
</div>
</template>

<script>
export default {
  emits: [
    'update:modelValue',
    'input',
    'submit',
    'clear',
  ],
  props: {
    modelValue: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: undefined },
  },
  methods: {
    submit() {
      this.$emit('submit', this.$refs.input.value)
    },
    input(event) {
      const val = event.target.value
      this.$emit('update:modelValue', val)
      if (val === '') this.$emit('clear')
      else this.$emit('input')
    }
  },
}
</script>

<style lang="scss" scoped>
.v-input-search {
  display: inline-block;
  position: relative;
  &:disabled { opacity: .35; }
  &__input {
    width: 100%;
    height: inherit;
    padding: .75em 1em;
    border: 1px solid #0002;
    border-radius: 1em;
    font-size: 1em !important;
    &::placeholder {
      color: #0004;
    }
  }
  &__icon {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%,-50%);
    font-size: 1.5em;
    &:hover { opacity: .95 }
    &:active { opacity: .8 }
  }
}
</style>