<template>
<div class="v-input-text"
  :class="{ centered: direction=='row' }">

  <div class="v-input-text__label"
    v-if="label">
    {{ label }}
    <span v-if="required" style="color:red">*</span>
  </div>

  <div>

    <div class="v-input-text__content">
      <div class="text-primary v-input-text__prefix"
        v-if="prefix">
        {{ prefix }}
      </div>
      <span class="material-symbols-outlined v-input-text__icon"
        v-if="areControlsVisible"
        @click="model += -step">
        remove
      </span>
      <div class="v-input-text__input-wrapper">
        <input class="v-input-text__input"
          :class="{ invalid: v$.$error }"
          ref="input"
          v-if="!rows"
          v-model.trim="model"
          v-bind="bindingInput"
          @paste="$event => pasteText($event)">
        <textarea class="v-input-text__input"
          :class="{ invalid: v$.$error }"
          v-else
          v-model="model"
          v-bind="bindingInput">
        </textarea>
        <span></span>
      </div>
      <i class="material-symbols-outlined v-input-text__icon"
        v-if="isPassword"
        @click="togglePassword()">
        {{ isPasswordVisible ? "visibility" : "visibility_off" }}
      </i>
      <i class="material-symbols-outlined v-input-text__icon"
        v-if="isCopiable"
        @click="copyToClipboard()">
        {{ isCopied ? "done" : "content_copy" }}
      </i>
      <span class="material-symbols-outlined v-input-text__icon"
        v-if="areControlsVisible"
        @click="model += step">
        add
      </span>
    </div>

    <div class="text-error"
      v-if="isErrorMessageDisplayed && v$.modelValue.$error">
      <div v-if="v$.modelValue.required?.$invalid">Поле має бути заповнене</div>
      <div v-else-if="v$.modelValue.minValue?.$invalid">Значення має бути більшим або рівним {{ min }}</div>
      <div v-else-if="v$.modelValue.maxValue?.$invalid">Значення має бути меншим або рівним {{ max }}</div>
      <div v-else-if="v$.modelValue.minLength?.$invalid">Поле має містити щонайменше {{ minLength }} символів</div>
      <div v-else-if="v$.modelValue.email?.$invalid">Неправильний формат email</div>
      <div v-else-if="v$.modelValue?.sameAs.$invalid">Поля мають співпадати</div>
      <div v-else>
        <div v-for="(validation, i) in validations">
          <div v-if="v$.modelValue[`validation${i}`]?.$invalid">
            {{ validation?.text }}
          </div>
        </div>
      </div>
    </div>
    
  </div>

</div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minValue, maxValue, minLength, email, sameAs } from '@vuelidate/validators'
export default {
  emits: [
    'update:modelValue',
    'update',
    'paste'
  ],
  props: {
    isCopiable: { type: Boolean, required: false, default: false },
    isInFocus: { type: Boolean, required: false, default: false },
    isErrorMessageDisplayed: { type: Boolean, required: false, default: true },
    areControlsVisible: { type: Boolean, required: false, default: false },
    required: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    readonly: { type: Boolean, required: false, default: false },
    size: { type: [String, Number], required: false, default: 20 },
    type: { type: String, required: false, default: 'text' },
    direction: { type: String, required: false, default: 'column' },
    prefix: { type: String, required: false, default: undefined },
    modelValue: { type: [String, Number], required: false, default: undefined },
    value: { type: [String, Number], required: false, default: undefined },
    label: { type: String, required: false, default: undefined },
    name: { type: String, required: false, default: undefined },
    placeholder: { type: String, required: false, default: undefined },
    min: { type: Number, required: false, default: undefined },
    max: { type: Number, required: false, default: undefined },
    step: { type: [String, Number], required: false, default: 1 },
    minLength: { type: [String, Number], required: false, default: undefined },
    maxLength: { type: [String, Number], required: false, default: 64 },
    sameAs: { type: String, required: false, default: undefined },
    rows: { type: [String, Number], required: false, default: undefined },
    color: { type: String, required: false, default: '#404040' },
    validations: { type: Array, required: false, default: [] },
  },
  mounted() {
    if (this.isInFocus) this.$refs.input.focus()
  },
  data() {
    return {
      v$: useVuelidate(),
      isPassword: this.type=="password",
      isPasswordVisible: false,
      isCopied: false,
    }
  },
  validations() {
    return {
      modelValue: {
        required: this.required ? required : true,
        email: this.type=="email" ? email : true,
        minValue: (typeof this.min == 'number') ? minValue(this.min) : true,
        maxValue: (typeof this.max == 'number') ? maxValue(this.max) : true,
        minLength: (typeof this.minLength == 'number') ? minLength(this.minLength) : true,
        sameAs: this.sameAs===undefined ? true : sameAs(this.sameAs),
        ...(this.validations.reduce((acc,e,i) => Object.assign(acc, {
          [`validation${i}`]: str => {
            const regexp = new RegExp(e?.regexp)
            return regexp.test(str)
          }
        }), {}))
      }
    }
  },
  computed: {
    typeDisplayed() {
      return (this.type=="email" || this.isPasswordVisible) ? "text" : this.type
    },
    bindingInput() {
      return {
        type: (this.type=="email" || this.isPasswordVisible) ? "text" : this.type,
        value: this.modelValue,
        disabled: this.disabled,
        readonly: this.readonly,
        maxlength: this.maxLength==Infinity ? undefined : String(this.maxLength),
        placeholder: this.placeholder ?? this.label,
        rows: this.rows,
        size: String(this.size),
      }
    },
    model: {
      get() { return this.modelValue },
      set(val) {
        this.$emit('update:modelValue', val===undefined ? null : val)
        this.$emit('update', val)
      }
    }
  },
  methods: {
    pasteText(event) {
      const text = (event.clipboardData || window.clipboardData).getData("text")
      this.$emit('paste', text)
    },
    togglePassword() {
      this.isPasswordVisible = !this.isPasswordVisible
    },
    async copyToClipboard() {
      this.isCopied = true
      await navigator.clipboard.writeText(this.modelValue)
    },
  },
}
</script>

<style lang="scss">
.v-input-text {
  display: flex;
  flex-direction: v-bind(direction);
  gap: .75em;
  &.centered { align-items: center; }
  &__label {
    width: 100%;
    font-size: 1em;
    text-align: left;
    line-height: 1.5;
    color: #0008;
  }
  &__content {
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #d3d3d3;
    background-color: #00000002;
  }
  &__prefix {
    position: relative;
    left: .5em;
  }
  &__input-wrapper {
    flex-grow: 1;
    width: 100%;
  }
  &__icon {
    cursor: pointer;
    font-size: 1.5em;
  }
  &__input {
    width: 100%;
    min-height: 2em;
    padding: 0 .5em;
    outline: none;
    border-style: hidden;
    font-family: 'Gilroy', sans-serif;
    font-size: 1em;
    line-height: 1.5;
    background: transparent;
    color: v-bind(color);
    font-weight: inherit;
    text-align: inherit;
    letter-spacing: inherit;
    &:read-only { opacity: .7; }
    &::-webkit-calendar-picker-indicator {
      cursor: pointer;
      margin: 0;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    & + span {
      position: absolute;
      left: 50%;
      bottom: -2px;
      width: 0;
      height: 2px;
      background: #a8a8a8;
      transition: .3s;
    }
    &.invalid + span {
      left: 0;
      width: 100%;
      background: #ff4d4d;
    }
    &:not(:read-only):focus + span {
      left: 0;
      width: 100%;
    }
    &:disabled {
      opacity: .5;
    }
    &::placeholder {
      color: #0003;
      font-weight: normal;
    }
  }
  &__error {
    margin: 5px 0 5px 0;
    text-align: left;
    color: #ff4d4d;
    font-size: .8em;
    font-weight: 500;
  }
}
</style>