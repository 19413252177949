<template>
<div class="v-datalist">

  <div class="v-datalist__label"
    v-if="label">
    {{ label }}
    <span v-if="required" style="color:red">*</span>
  </div>

  <div class="v-datalist__body">

    <div class="v-datalist__title">
      <div class="v-datalist__title-image-wrapper"
        v-if="itemSelected?.src">
        <img class="v-datalist__title-image"
          :src="`${itemSelected.src}?size=100`">
      </div>
      <i class="material-symbols v-datalist__title-icon"
        v-else-if="itemSelected?.icon">
        {{ itemSelected.icon }}
      </i>
      <div class="v-datalist__input-wrapper">
        <input class="v-datalist__input"
          :class="{ invalid: v$.$error }"
          type="text"
          v-model="model"
          v-bind="{ placeholder, required }"
          @click="isUnfolded = true">
        <span></span>
      </div>
      <i class="material-symbols-outlined v-datalist__icon"
        v-if="hasArrow"
        @mouseup="_ => isUnfolded = !isUnfolded">
        expand_more
      </i>
    </div>

    <div class="v-datalist__items"
      v-if="isUnfolded && itemsDisplayed.length"
      v-click-outside="() => isUnfolded = false"
      :style="{ [align]: 0 }">
      <div class="v-datalist__item"
        v-for="(item, i) in itemsDisplayed" 
        :key="item.value"
        :disabled="item.disabled ?? false"
        v-show="item.visible ?? true"
        @click="select(i)"
        :style="item.style">
        <i class="material-symbols"
          v-if="item.icon">
          {{ item.icon }}
        </i>
        <v-avatar class="v-datalist__item-avatar"
          v-if="areAvatarsVisible"
          :label="item.label ?? item.value"
          :src="item.src"
          size="1.5em">
        </v-avatar>
        <span class="v-datalist__item-label">
          {{ item.label ?? item.value }}
        </span>
      </div>
    </div>

    <div class="text-error"
      v-if="v$.$error && !isErrorMessageIgnored">
      Оберіть один з пунктів списку
    </div>

  </div>

</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
export default {
  emits: [
    'update:modelValue',
    'update',
  ],
  props: {
    isExpandedOnEmpty: { type: Boolean, required: false, default: false },
    hasArrow: { type: Boolean, required: false, default: false },
    isErrorMessageIgnored: { type: Boolean, required: false, default: false },
    areAvatarsVisible: { type: Boolean, required: false, default: false },
    hasImage: { type: Boolean, required: false, default: false },
    required: { type: Boolean, required: false, default: false },
    modelValue: { type: [Boolean, Number, String], required: false, default: undefined },
    label: { type: String, required: false, default: undefined },
    items: { type: Array, required: true, default: [] },
    align: { type: String, required: false, default: 'stretch' },
    width: { type: String, required: false, default: '100%' },
    height: { type: String, required: false, default: 'auto' },
    placeholder: { type: String, required: false, default: "Почніть вводити значення" },
  },
  mounted() {
    this.model = this.items.find(e => e.value==this.modelValue)?.label
  },
  data() {
    return {
      v$: useVuelidate(),
      isUnfolded: false,
      model: undefined,
    }
  },
  validations() {
    return {
      model: {
        valid: val => (this.items.findIndex(e => e.label==val) >= 0) || (this.required ? false : !val)
      },
    }
  },
  computed: {
    itemsDisplayed() {
      if (this.isExpandedOnEmpty && !this.model) return this.items
      return this.items.filter(e => e?.label?.toLowerCase()?.includes(this.model?.toLowerCase()))
    },
    itemSelected() {
      return this.items.find(e => e.value==this.modelValue)
    },
  },
  methods: {
    select(index) {
      const item = this.itemsDisplayed[index]
      if (item.action) item.action()
      this.model = item.label
      this.isUnfolded = false
      this.$emit("update:modelValue", item.value)
    },
  },
  watch: {
    model: {
      handler(val) {
        const item = this.items.find(e => e.label==val)
        this.$emit("update:modelValue", item?.value ?? val)
        this.$emit("update", item?.payload)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.v-datalist {
  &__body {
    position: relative;
  }
  &__label {
    margin-bottom: .71em;
    font-size: 1em;
    text-align: left;
    line-height: 1.5;
    color: #0008;
  }
  &__input {
    width: 100%;
  }
  &__icon {
    font-size: 1.5em;
  }
  &__title {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: .5em;
    border-bottom: 2px solid #d3d3d3;
    background-color: #00000002;
  }
  &__title-image-wrapper {
    flex-shrink: 0;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background-color: #0002;
  }
  &__title-image {
    width: 100%;
    display: block;
    object-fit: contain;
    border-radius: 50%;
  }
  &__title-icon {
    font-size: 1.25em;
  }
  &__icon {
    cursor: pointer;
    font-size: 1.5em;
  }
  &__error {
    margin: 5px 0;
    color: #ff4d4d;
    font-size: .8em;
    font-weight: 500;
  }
  &__input-wrapper {
    flex-grow: 1;
    width: 100%;
  }
  &__input {
    width: 100%;
    min-height: 2em;
    padding: 0 .5em;
    outline: none;
    border-style: hidden;
    font-family: 'Gilroy';
    font-size: 1em;
    line-height: 1.5;
    background: transparent;
    color: v-bind(color);
    font-weight: inherit;
    text-align: inherit;
    letter-spacing: inherit;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    & + span {
      position: absolute;
      left: 50%;
      bottom: -2px;
      width: 0;
      height: 2px;
      background: #a8a8a8;
      transition: .3s;
    }
    &.invalid + span {
      left: 0;
      width: 100%;
      background: #ff4d4d;
    }
    &:focus + span {
      left: 0;
      width: 100%;
    }
    &:disabled {
      color: #404040;
    }
    &::placeholder {
      color: v-bind(color);
      font-weight: normal;
      opacity: .5;
    }
  }
  &__items {
    position: absolute;
    z-index: 10;
    width: v-bind(width);
    height: v-bind(height);
    padding: .25em 0;
    border-radius: 1em;
    box-shadow: 0 2px 16px #0003;
    background: white;
    overflow-y: scroll;
  }
  &__item {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    column-gap: .75em;
    cursor: pointer;
    min-width: 100%;
    padding: .5em 1em;
    font-weight: normal;
    color: initial;
    line-height: 1.5;
    text-transform: none;
    &:hover { background-color: #0001; }
    &:active { background-color: #0002; }
    &:disabled {
      cursor: initial;
      opacity: .35;
    }  
  }
  &__item-label {
    display: block;
  }
  &__item-image {
    flex-shrink: 0;
    width: 1.5em;
    display: block;
    object-fit: contain;
    border-radius: 50%;
  }
}
</style>